import Image from 'next/image';
import React, { useEffect, useCallback, useState, FC } from 'react';
import useEmblaCarousel from 'embla-carousel-react';
import { BodyText, H2, H4 } from '../../../ui/core/Typography/typography.styles';
import { useHasMounted } from '../../../hooks/useHasMounted';
import { processCmsImageUrl } from '../../../utils/urls';
import { ButtonGroup } from '../../../ui/core/Buttons/Buttons';
import Button from '../../../models/strapi/Button';
import { Icon, ICON_TYPE } from '../../IconComponent/IconComponent';
import { CircleNextBtn, CirclePrevBtn } from '../../EmblaCarousel/EmblaActionButtons.styles';
import { ProfileCard } from './ProfileCard';
import ProfileList from '../../../models/strapi/ProfileList';
import {
	InfoContainer,
	BackgroundMask,
	ColorMask,
	InfoSection,
	TopSection,
	InfoImage,
	InfoContent,
	BottomSection,
	BottomHead,
	CarouselButtons,
	CarouselSection,
	ViewRef,
	CarouselContent,
} from './InfoComponent.styles';

type InfoProps = {
	imageUrl: string;
	title: string;
	description: string;
	button: Button;
	profiles?: ProfileList;
};

export const InfoComponent: FC<InfoProps> = ({ imageUrl, title, description, button, profiles }) => {
	const hasMounted = useHasMounted();
	const isBigList = profiles?.profileCards?.length > 4 ?? false;
	const [viewportRef, embla] = useEmblaCarousel({
		active: true,
		skipSnaps: false,
		align: 'start',
		containScroll: 'trimSnaps',
		inViewThreshold: 100,
	});
	const [prevBtnEnabled, setPrevBtnEnabled] = useState<boolean>(false);
	const [nextBtnEnabled, setNextBtnEnabled] = useState<boolean>(false);
	const scrollPrev = useCallback(() => {
		if (!embla) return;
		embla.scrollPrev();
	}, [embla]);
	const scrollNext = useCallback(() => {
		if (!embla) return;
		embla.scrollNext();
	}, [embla]);
	const onSelect = useCallback(() => {
		if (!embla) return;
		setPrevBtnEnabled(embla.canScrollPrev());
		setNextBtnEnabled(embla.canScrollNext());
	}, [embla]);

	useEffect(() => {
		if (embla) {
			embla.on('select', onSelect);
			embla.on('resize', onSelect);
			onSelect();
		}
	}, [embla, onSelect]);

	if (!hasMounted) {
		return null;
	}

	return (
		<InfoContainer>
			<BackgroundMask>
				<ColorMask id="mask1" />
				<ColorMask id="mask2" />
				<ColorMask id="mask3" />
				<ColorMask id="mask4" />
				<ColorMask id="mask5" />
			</BackgroundMask>
			<InfoSection>
				<TopSection>
					<InfoImage>
						<Image layout="fill" objectFit="cover" src={processCmsImageUrl(imageUrl)} />
					</InfoImage>
					<InfoContent>
						<H2>{title}</H2>
						<BodyText>{description}</BodyText>
						<ButtonGroup text={button.text} type={button.type} url={button.url} />
					</InfoContent>
				</TopSection>
				{profiles && (
					<BottomSection>
						<BottomHead>
							<H4>{profiles?.heading}</H4>
							<CarouselButtons>
								<CircleNextBtn onClick={scrollNext} disabled={!nextBtnEnabled}>
									<Icon type={ICON_TYPE.ARROW_RIGHT_L} />
								</CircleNextBtn>
								<CirclePrevBtn onClick={scrollPrev} disabled={!prevBtnEnabled}>
									<Icon type={ICON_TYPE.ARROW_RIGHT_L} />
								</CirclePrevBtn>
							</CarouselButtons>
						</BottomHead>
						<CarouselSection>
							<ViewRef ref={viewportRef}>
								<CarouselContent isRow={isBigList}>
									{profiles?.profileCards?.map((profile) => (
										<ProfileCard
											key={profile.id}
											isRow={isBigList}
											imageUrl={profile.image.data.attributes.url}
											alternativeText={profile.image.data.attributes.alternativeText}
											name={profile.name}
											description={profile.description}
										/>
									))}
								</CarouselContent>
							</ViewRef>
						</CarouselSection>
					</BottomSection>
				)}
			</InfoSection>
		</InfoContainer>
	);
};
