import React from 'react';
import ContentSection from '../../models/strapi/ContentSection/ContentSection';
import CalloutComponent from '../CalloutComponent';
import { WhereToWatch } from '../WhereToWatch/WhereToWatch';
import { PackagesCarousel } from '../Package/PackagesCarousel';
import { SportLogoCarousels } from '../SportLogosLive/SportLogosLive';
import { ImageCarousel } from '../ImageCarousel';
import { TitleComponent } from '../StrapiComponents/TitleComponent';
import { InfoComponent } from '../StrapiComponents/InfoComponent/InfoComponent';
import { InfoCardsComponent } from '../StrapiComponents/InfoCardComponent';
import { GalleryComponent } from '../StrapiComponents/GalleryComponent';
import { FaqComponent } from '../StrapiComponents/FaqComponent';
import { PriceTableComponent } from '../PriceTableComponent';
import TopListCarousel from '../TopListCarousel';
import PresentationCarousel from '../PresentationCarousel';

type ContentSectionProps = {
	section: ContentSection;
};

const ContentSectionComponent: React.FC<ContentSectionProps> = ({ section }) => {
	return (
		<>
			{!!section?.titlePanel?.title && <TitleComponent titleText={section.titlePanel.title} textAlignment={section.titlePanel.titleAlignment} />}
			{section?.features?.length > 0 && <CalloutComponent callouts={section.features} />}
			{!!section?.infoPanel?.title && (
				<InfoComponent
					imageUrl={section.infoPanel.image.data.attributes.url}
					title={section.infoPanel.title}
					description={section.infoPanel.description}
					button={section.infoPanel.button}
					profiles={section.infoPanel.profiles}
				/>
			)}
			{section?.cardPanel?.infoCards?.length > 0 && <InfoCardsComponent cards={section.cardPanel.infoCards} />}
			{section?.galleryPanel && (
				<GalleryComponent title={section.galleryPanel.title} description={section.galleryPanel.description} label={section.galleryPanel.label} images={section.galleryPanel.images.data} />
			)}
			{section?.faqPanel?.questions?.length > 0 && <FaqComponent title={section.faqPanel.title} description={section.faqPanel.description} questions={section.faqPanel.questions} />}
			{section?.cards?.length > 0 && <WhereToWatch cards={section.cards} />}
			{section?.packages?.length > 0 && <PackagesCarousel packages={section.packages} />}
			{section?.sportLogos?.length > 0 && <SportLogoCarousels sportLogos={section.sportLogos} />}
			{section?.imageCarousels?.length > 0 &&
				section.imageCarousels.map((imageCarousel) => {
					return (
						<ImageCarousel
							key={imageCarousel.id}
							images={imageCarousel.images}
							bannerSize={imageCarousel.size}
							buttons={imageCarousel.buttons}
							dots={imageCarousel.dots}
							isLooping={imageCarousel.loop}
							useAutoplay={imageCarousel.autoplay}
						/>
					);
				})}
			{section?.priceTablePanel?.priceTables?.length > 0 && (
				<PriceTableComponent title={section.priceTablePanel.title} dateLabel={section.priceTablePanel.dateLabel} priceTables={section.priceTablePanel.priceTables} />
			)}
			{section?.topListCarousel?.mediaList?.length > 0 && (
				<TopListCarousel id={section.topListCarousel.id} title={section.topListCarousel.title} topList={section.topListCarousel} useStrapi categoryID={section.topListCarousel.categoryID} />
			)}
			{section?.presentationCarousel?.mediaList?.length > 0 && (
				<PresentationCarousel
					id={section.presentationCarousel.id}
					title={section.presentationCarousel.title}
					presentation={section.presentationCarousel}
					categoryID={section.presentationCarousel.categoryID}
					useStrapi
				/>
			)}
		</>
	);
};

export default ContentSectionComponent;
