import React, { FC, useEffect, useState } from 'react';
import { Category, ModuleType, ModuleTemplate, useCategory } from '@vodafoneis/sjonvarpskjarni-js-lib';
import { useTheme } from 'styled-components';
import { useWindowSize } from 'react-use';
import useContentGridRenderer from '../hooks/useContentGridRenderer';
import { EmblaMediaCarousel } from './EmblaCarousel/EmblaMediaCarosel';
import TopList from '../models/strapi/TopListCarousel';
import { CONTENT_LIMIT } from '../config/constants';

type TopListCarouselProps = {
	id: string;
	title?: string;
	category?: Category;
	topList?: TopList;
	categoryID?: number;
	useStrapi?: boolean;
};

const TopListCarousel: FC<TopListCarouselProps> = ({ id, title, category, topList, useStrapi, categoryID }) => {
	const { width } = useWindowSize();
	const { screenLayout } = useTheme();
	const [moduleType, setModuleType] = useState<ModuleType>();
	const moduleTemplate = ModuleTemplate.PROMO;
	const categoryFromId = useCategory(categoryID, CONTENT_LIMIT);

	useEffect(() => {
		if (width < screenLayout.tabletLandscape) {
			setModuleType(ModuleType.POSTER);
		}
		if (width >= screenLayout.tabletLandscape) {
			setModuleType(ModuleType.M4);
		}
	}, [width, screenLayout]);

	const renderItem = useContentGridRenderer({ moduleType, moduleTemplate });

	let content;
	if (!useStrapi) {
		if (!category.content) return null;
		content = category.content;
	} else {
		content = categoryFromId.category ? categoryFromId?.category?.content : topList.mediaList;
	}

	return <EmblaMediaCarousel id={id} title={title || category.title} items={content} renderItem={renderItem} isTopList />;
};

export default TopListCarousel;
