import Image from 'next/image';
import React, { FC } from 'react';
import styled from 'styled-components';
import { BodyText, H2, TagTextSmall } from '../ui/core/Typography/typography.styles';
import { media } from '../ui/utils/media';
import { useHasMounted } from '../hooks/useHasMounted';
import { processCmsImageUrl } from '../utils/urls';
import { ButtonGroup } from '../ui/core/Buttons/Buttons';
import Feature from '../models/strapi/Feature';
import Button from '../models/strapi/Button';

const CalloutContainer = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 32px;
	padding: 64px 0px;

	${media.desktopUp} {
		gap: 64px;
	}
`;

type FlexDirectionProps = 'row' | 'column' | 'row-reverse' | 'column-reverse';

const FlexContainer = styled.div<{ flexDirection: FlexDirectionProps }>`
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
	max-width: 1440px;
	gap: 32px;

	${media.tabletLandscapeUp} {
		flex-direction: ${({ flexDirection }) => flexDirection};
	}
`;

type PositionProps = 'right' | 'left' | 'bottom' | 'top';

const TextContentContainer = styled.div<{ paddingPosition: PositionProps }>`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	width: 100%;
	gap: 18px;
	${media.tabletLandscapeUp} {
		width: 50%;
		margin: ${({ paddingPosition }) => (paddingPosition === 'right' ? '16px 0px 16px 96px' : '16px 96px 16px 0px')};
		padding: ${({ paddingPosition }) => (paddingPosition === 'right' ? '0px 48px 0px 0px' : '0px 0px 0px 48px')};
	}
	${media.desktopUp} {
		padding: ${({ paddingPosition }) => (paddingPosition === 'right' ? '0px 112px 0px 0px' : '0px 0px 0px 112px')};
	}
`;
const ParagraphsContainer = styled.div``;

const ImageContainer = styled.div<{ position: PositionProps }>`
	display: flex;
	position: relative;
	align-items: flex-start;
	width: 100%;
	min-height: 400px;
	overflow: hidden;
	border-radius: 8px;
	margin: 0;

	${media.tabletLandscapeUp} {
		width: 50%;
		margin: ${({ position }) => (position === 'right' ? '16px 96px 16px 0px' : '16px 0px 16px 96px')};
	}
`;

type CalloutProps = {
	callouts: Feature[];
};

type FiftyFiftyProps = {
	disabled?: boolean;
	imageUrl: string;
	tag?: string;
	title: string;
	mediaPosition: PositionProps;
	paragraphs?: string[];
	alternativeText?: string;
	button?: Button;
};

const FiftyFiftyContainer: FC<FiftyFiftyProps> = ({ disabled, imageUrl, tag, title, mediaPosition, paragraphs, alternativeText, button }) => {
	const handleMediaPosition = (position) => {
		switch (position) {
			case 'right':
				return 'row-reverse';
			case 'left':
				return 'row';
			case 'top':
				return 'column';
			case 'bottom':
				return 'column-reverse';
			default:
				return 'row';
		}
	};

	return (
		<>
			{!disabled ? (
				<FlexContainer flexDirection={handleMediaPosition(mediaPosition)}>
					<ImageContainer position={mediaPosition}>
						<Image layout="fill" objectFit="cover" src={processCmsImageUrl(imageUrl)} alt={alternativeText} />
					</ImageContainer>
					<TextContentContainer paddingPosition={mediaPosition}>
						{!!tag && <TagTextSmall>{tag}</TagTextSmall>}
						<H2>{title}</H2>
						<ParagraphsContainer>
							{paragraphs &&
								paragraphs.map((p, index) => (
									// eslint-disable-next-line react/no-array-index-key
									<BodyText key={index}>{p}</BodyText>
								))}
						</ParagraphsContainer>
						{button && <ButtonGroup text={button.text} type={button.type} url={button.url} />}
					</TextContentContainer>
				</FlexContainer>
			) : (
				<> </>
			)}
		</>
	);
};

const CalloutComponent: FC<CalloutProps> = ({ callouts }) => {
	const hasMounted = useHasMounted();

	if (!hasMounted) {
		return null;
	}

	return (
		<CalloutContainer>
			{callouts?.map((feature) => {
				return (
					<FiftyFiftyContainer
						key={feature.id}
						imageUrl={feature?.media?.data?.attributes?.url}
						tag={feature.label}
						title={feature.title}
						mediaPosition={feature.mediaPosition}
						paragraphs={feature.description?.split('\n')}
						alternativeText={feature?.media?.data?.attributes?.alternativeText}
						button={feature.button}
					/>
				);
			})}
		</CalloutContainer>
	);
};

export default CalloutComponent;
