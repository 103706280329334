import Image from 'next/image';
import React, { useEffect, useCallback, useState, useRef } from 'react';
import useEmblaCarousel from 'embla-carousel-react';
import Autoplay from 'embla-carousel-autoplay';
import styled from 'styled-components';
import { Icon, ICON_TYPE } from './IconComponent/IconComponent';
import { EmblaDotButton, NextBtn, PrevBtn } from './EmblaCarousel/EmblaActionButtons.styles';
import { processCmsImageUrl } from '../utils/urls';
import { BANNER_CAROUSEL_SIZES, BANNER_CAROUSEL_AUTOPLAY_SPEED } from '../config/constants';

const ImageCarouselContainer = styled.div`
	display: flex;
	position: relative;
	flex-direction: column;
	margin: 100px 0px;
	gap: 48px;
`;

const CarouselRef = styled.div`
	overflow: hidden;
`;

const CarouselContent = styled.div`
	display: flex;
	flex-direction: row;
	height: auto;
	user-select: none;
	-webkit-touch-callout: none;
	-khtml-user-select: none;
	-webkit-tap-highlight-color: transparent;
`;

const Dots = styled.div`
	display: flex;
	list-style: none;
	justify-content: center;
	position: absolute;
	width: 100%;
	top: 80%;
`;

const ImageSlide = styled.div`
	position: relative;
	flex: 0 0 100%;
	min-width: 0;
`;

const ImageItem = styled.a<{ hasLink?: boolean; bannerSize: number }>`
	display: block;
	pointer-events: ${({ hasLink }) => (hasLink ? 'auto' : 'none')};
	cursor: ${({ hasLink }) => (hasLink ? 'pointer' : 'not-allowed')};
	width: 100%;
	height: ${({ bannerSize }) => bannerSize}px;
`;

type ImageCarouselProps = {
	disabled?: boolean;
	images: any[];
	bannerSize: string;
	buttons: boolean;
	dots: boolean;
	isLooping: boolean;
	useAutoplay: boolean;
};

export const ImageCarousel: React.FC<ImageCarouselProps> = ({ disabled, images, bannerSize, buttons, dots, isLooping, useAutoplay }) => {
	const [scroll, setScroll] = useState(true);
	const [size, setSize] = useState(0);
	const autoplay = useRef(
		Autoplay({
			delay: useAutoplay ? BANNER_CAROUSEL_AUTOPLAY_SPEED : 0,
			stopOnInteraction: false,
			playOnInit: false,
		})
	);

	const [viewportRef, embla] = useEmblaCarousel(
		{
			active: scroll,
			skipSnaps: false,
			align: 'start',
			containScroll: 'trimSnaps',
			loop: isLooping,
			inViewThreshold: 100,
		},
		[autoplay.current]
	);
	const [prevBtnEnabled, setPrevBtnEnabled] = useState(false);
	const [nextBtnEnabled, setNextBtnEnabled] = useState(false);
	const scrollPrev = useCallback(() => {
		if (!embla) return;
		embla.scrollPrev();
		autoplay.current.reset();
	}, [embla]);
	const scrollNext = useCallback(() => {
		if (!embla) return;
		embla.scrollNext();
		autoplay.current.reset();
	}, [embla]);
	const [selectedIndex, setSelectedIndex] = useState(0);
	const [scrollSnaps, setScrollSnaps] = useState([]);
	const scrollTo = useCallback((index) => embla && embla.scrollTo(index), [embla]);

	const onSelect = useCallback(() => {
		if (!embla) return;
		setSelectedIndex(embla.selectedScrollSnap());
		setPrevBtnEnabled(embla.canScrollPrev());
		setNextBtnEnabled(embla.canScrollNext());
	}, [embla, setSelectedIndex]);

	const getSize = useCallback(() => {
		switch (bannerSize) {
			case 'small':
				return BANNER_CAROUSEL_SIZES.SMALL;
			case 'medium':
				return BANNER_CAROUSEL_SIZES.MEDIUM;
			case 'large':
				return BANNER_CAROUSEL_SIZES.LARGE;
			default:
				return BANNER_CAROUSEL_SIZES.SMALL;
		}
	}, [bannerSize]);

	useEffect(() => {
		if (!embla) return;
		if (images.length <= 1) {
			setScroll(true);
		}
		if (!useAutoplay) {
			embla.plugins().autoplay.stop();
		} else {
			embla.plugins().autoplay.play();
		}
		setSize(getSize());
		setScrollSnaps(embla.scrollSnapList());
		onSelect();
		embla.on('select', onSelect);
		embla.on('resize', onSelect);
	}, [embla, setScrollSnaps, onSelect, getSize, images, useAutoplay]);

	const renderItem = (slide) => {
		const hasLink = !!slide?.link ?? false;

		return (
			<ImageSlide key={slide.id}>
				<ImageItem bannerSize={size} hasLink={hasLink} href={slide.link} target="_blank" rel="noopener noreferrer">
					<Image layout="fill" objectFit="cover" src={processCmsImageUrl(slide.image.data.attributes.url)} />
				</ImageItem>
			</ImageSlide>
		);
	};

	return (
		<>
			{!disabled && images ? (
				<ImageCarouselContainer>
					<CarouselRef ref={viewportRef}>
						<CarouselContent>{images?.map((image) => renderItem(image))}</CarouselContent>
					</CarouselRef>
					{buttons && (
						<>
							<PrevBtn onClick={scrollPrev} disabled={!prevBtnEnabled}>
								<Icon type={ICON_TYPE.ARROW_RIGHT_L} />
							</PrevBtn>
							<NextBtn onClick={scrollNext} disabled={!nextBtnEnabled}>
								<Icon type={ICON_TYPE.ARROW_RIGHT_L} />
							</NextBtn>
						</>
					)}
					{dots && (
						<Dots>
							{scrollSnaps.map((_, index) => (
								// eslint-disable-next-line react/no-array-index-key
								<EmblaDotButton key={`${index}`} selected={index === selectedIndex} onClick={() => scrollTo(index)} />
							))}
						</Dots>
					)}
				</ImageCarouselContainer>
			) : null}
		</>
	);
};

ImageCarousel.defaultProps = {
	disabled: false,
};
